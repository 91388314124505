import { useState } from "react";

import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Caption from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

import photos from "../photos";

const slides = photos.map(({ src, width, height, images, link }) => ({
  src,
  width,
  height,
  title: (<>{src.split('/')[src.split('/').length - 1]} <a href={link} target="_blank" className="slideshow-link" rel="noopener noreferrer">(Télécharger)</a></>),
  description: '',
  srcSet: images.map((image) => ({
    src: image.src,
    width: image.width,
    height: image.height,
  })),
}));

const Main = () => {
  const [index, setIndex] = useState(-1);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{ width: '100%', marginTop: '2em', marginBottom: '2em' }}>
          <CardContent>
            <Typography sx={{ fontSize: '1.4em', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
              Les Photos !
            </Typography>

            <div style={{
              paddingTop: '2em',
              paddingBottom: '2em',
            }}>
              <Typography variant="body2" style={{
                textAlign: 'left'
              }}>
                Si vous avez des questions n'hésitez pas à nous écrire au 06 73 16 21 13 (Maxime) ou au 06 66 52 57 52 (Mickael) ou encore par email à <a href="mailto:mariage@maxime-et-mickael.fr">mariage@maxime-et-mickael.fr</a>
              </Typography>
            </div>

            <PhotoAlbum
              photos={photos}
              layout="masonry"
              onClick={(event, photo, index) => setIndex(index)}
            />
          </CardContent>
        </Card>
      </Box>

      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Caption]}
      />
    </Container>
  );
};

export default Main;
