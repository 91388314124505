import './App.css';
import Login from './components/Login'
import {useState} from 'react';
import Main from './components/Main';

function App() {
  const [step, setStep] = useState("LOGIN");

  return (
    <div className="App">
      <div style={{
        display: 'inline-block',
        zIndex: -20,
        lineHeight: 0,
        opacity: 1,
        backgroundColor: 'rgb(94, 94, 92)',
        backgroundImage: `url("/large.png")`,
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        height: '100%',
        width: '100%',
        position: 'fixed',
        top: '0px',
        left: '0px'
      }}></div>

      { step === 'LOGIN' && (
        <div style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Login goNext={() => setStep("MAIN")}/>
        </div>
      )}

      {step === 'MAIN' && (
        <div style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Main />
        </div>
      )}
    </div>
  );
}

export default App;
