import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import sha256 from 'crypto-js/sha256';
import encHex from 'crypto-js/enc-hex';
import Alert from '@mui/material/Alert';
import { createTheme } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{
      color: '#fff'
    }}>
      {'Maxime & Mickael 2022'}
    </Typography>
  );
}

const theme = createTheme();

export default function Login({ goNext }) {
  const [login, setLogin] = useState("");
  const [isError, setIsError] = useState(false);

  const onChange = (evt) => {
    setLogin(evt.target.value);
    setIsError(false);
  }

  const validate = () => {
    const ALLOWED = [
      'fa8f02b6b70e6ce306169b13f0007f80a1664fea3fac8d8dae9901cc9ca6a2ab',
      '67084ad59ac1975afe9170fd1be709c5a16eff355ed09f6ddafdd3b8be5719da',
      '3aab9058279b52abe4a2089959dcdb01f2d9e462e2e055fed2c76493a8d8c76c',
      '26a527c8f28a9d18890b1d5c6196e1e1de86dd2fc9ed9536f5c786b4227b3cd9',
      'a9c82f40c877f78fb3370f3313d910cacac018df6ae3fb264ade561fa8e872b4',
      '87b205e801b17013c3d92707b364b6e85b2aa2593f574ef2d6af1247a9f8ceae',
    ];
    const hash = sha256(login).toString(encHex);

    if (ALLOWED.includes(hash)) {
      goNext();
    } else {
      setIsError(true);
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Avatar sx={{
          backgroundColor: theme.palette.primary.light
        }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5" style={{
          color: '#fff',
          fontWeight: 'bold'
        }}>
          Quel est le mot de passe ? (à trouver sur le faire part !)
        </Typography>
        <form sx={{
          width: '100%'
        }} noValidate>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            color="primary"
            autoComplete="current-password"
            onChange={onChange}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                ev.preventDefault();
                validate();
              }
            }}
          />
          { isError && (
            <Box sx={{
              padding: '1em'
            }}>
              <Alert severity="error">Mot de passe invalide</Alert>
            </Box>
          )}
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={validate}
          >
            Valider
          </Button>
        </form>
      </Box>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}